import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// Import the external CSS file for Navbar styles
import './Navbar.css'; 
import jjphotosLogo from '../images/jjphotosLogo-white.png';

const Navbar = () => {
    const [isActive, setisActive] = useState(false);
    function toggleBurgerMenu() {
        setisActive(!isActive);
      }
    return (
        <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link to="/" className="navbar-item">
                    <img src= {jjphotosLogo} alt="Logo" />
                </Link>
                <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic" onClick={toggleBurgerMenu}> 
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navbarBasic" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <div className='navbar-end'>
                   <Link to="/" className="navbar-item" onClick={toggleBurgerMenu}>Home</Link>
                   <Link to="/landscapes" className="navbar-item" onClick={toggleBurgerMenu}>Landscapes</Link>
                   <Link to="/wildlife" className="navbar-item" onClick={toggleBurgerMenu}>Wildlife</Link>
                   <Link to="/favourites" className="navbar-item" onClick={toggleBurgerMenu}>Favourites</Link>
                   <Link to="/about" className="navbar-item" onClick={toggleBurgerMenu}>About</Link>
                </div>    
            </div>
        </nav>
    );
};

export default Navbar;
