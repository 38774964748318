import React, {useState} from 'react';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { imagesLandscape } from '../assets/landscapeImages';

const LandscapeGallery = () => {
    const [index, setIndex] = useState(-1);

    const currentImage = imagesLandscape[index];
    const nextIndex = (index + 1) % imagesLandscape.length;
    const nextImage = imagesLandscape[nextIndex] || currentImage;
    const prevIndex = (index + imagesLandscape.length - 1) % imagesLandscape.length;
    const prevImage = imagesLandscape[prevIndex] || currentImage;
  
    const handleClick = (index) => {
      setIndex(index);
    }
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);  
 return (
        <div>   
        <Gallery 
          images={imagesLandscape} 
          onClick={handleClick} 
          enableImageSelection={false}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
        </div> 
    );

};

export default LandscapeGallery;