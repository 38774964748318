import React from "react";
import HomePageImage from '../images/IMG_1490s.jpg'

const Introduction = () => {
    return (
        <>
        <article className="message">
            <div className="introduction">
                <div className="columns">
                    <div className="column">
                        <div className="text">
                            <h1>
                                Jeremy's Photos
                            </h1>
                            <p>
                                This is my personal website. I have no buttons to press like or such things, since I'm not a big fan of
                                social media.
                            </p>
                            <p>
                                This website is just a collection of some of the photos I have taken, so feel free to have a browse and if you want to, 
                                please get in touch. 
                            </p>
                            <p>Meanwhile, here's one I liked so much, we have it on a wall at home.</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="image">
                            <img
                                src={HomePageImage}
                                alt="home page image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </article>
        </>
    );
};

export default Introduction;
