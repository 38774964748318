import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from '../src/components/Navbar';
import HomePage from '../src/components/HomePage';
import FavouritePhotos from '../src/components/FavouritePhotos';
import AboutMe from '../src/components/AboutPage';
import WildlifeGallery from './components/Wildlife';
import LandscapeGallery from './components/Landscapes';
import CryptoNewsPanel from './components/CryptoNews';
import '../src/App.css';
// Styles
import './assets/styles.scss'; 

function App() {
  return (
    <Router>
    <div>
        <Navbar />
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/landscapes" element={<LandscapeGallery />} />
        <Route path="/wildlife" element={<WildlifeGallery />} />
        <Route path="/favourites" element={<FavouritePhotos />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/crypto" element={<CryptoNewsPanel />} />

        </Routes>
    </div>
    </Router>
  );
}

export default App;
