export const imagesLandscape = [
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1725284752/JT3A1131_vtfghx.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1725284752/JT3A1131_vtfghx.jpg",
       width: 320,
       caption: "Lindisfarne Moody Night",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1725284753/JT3A1101_f1phy8.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1725284753/JT3A1101_f1phy8.jpg",
       width: 320,
       caption: "Holy Island Refuge Box",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1725284734/JT3A1122-Enhanced-NR_nxi2v5.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1725284734/JT3A1122-Enhanced-NR_nxi2v5.jpg",
       width: 320,
       caption: "Holy Island, last light of the day.",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-16_zes94z.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-16_zes94z.jpg",
       width: 320,
       caption: "Waterfall",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-4_romstt.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-4_romstt.jpg",
       width: 640,
       height: 212,
       caption: "Icelandic Sunrise",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-17_yjsyvz.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-17_yjsyvz.jpg",
       width: 640,
       height: 427,
       caption: "Three Sisters, Glencoe",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-8_iw0xlb.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-8_iw0xlb.jpg",
       width: 320,
       height: 212,
       caption: "Icelandic Morning",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-10_nf0ajd.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377674/fav-10_nf0ajd.jpg",
       width: 320,
       height: 212,
       caption: "Yorkshire Dales",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556928/fav-106_flxj8u.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723556928/fav-106_flxj8u.jpg",
       width: 320,
       height: 212,
       caption: "Icelandic House",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723555440/HDR2_05And8more_mswaxi.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723555440/HDR2_05And8more_mswaxi.jpg",
       width: 320,
       height: 212,
       caption: "Spanish Sunflowers",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723541478/IMG_2695_vpoojo.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723541478/IMG_2695_vpoojo.jpg",
       width: 320,
       height: 212,
       caption: "The Arctic Henge, Raufarhöfn, Iceland",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377675/fav-11_tgtmwi.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377675/fav-11_tgtmwi.jpg",
       width: 320,
       height: 212,
       caption: "Lossiemouth Beach",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377673/fav-19_kidshq.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377673/fav-19_kidshq.jpg",
       width: 320,
       height: 212,
       caption: "Middleton Woods, Ilkley",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-18_gekmwm.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-18_gekmwm.jpg",
       width: 320,
       height: 212,
       caption: "Keswick",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-6_zwm9ne.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-6_zwm9ne.jpg",
       width: 320,
       height: 212,
       caption: "Aurora Borealis, Iceland",
    },
    {
       src: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-2_h5s35i.jpg",
       original: "https://res.cloudinary.com/durvgxd0n/image/upload/v1723377672/fav-2_h5s35i.jpg",
       width: 320,
       height: 212,
       caption: "Broughton Hall",
    },
    ];