import React from "react";

const CryptoNewsPanel = () => {
    return (
        <>
        <article className="message">
            <div className="introduction">
                <div className="columns">
                    <div className="column">
                        <iframe width="100%" scrolling="yes" allowtransparency="true" frameborder="0" src="https://cryptopanic.com/widgets/news/?bg_color=FFFFFF&amp;currencies=KAS&amp;font_family=sans&amp;header_bg_color=30343B&amp;header_text_color=FFFFFF&amp;link_color=0091C2&amp;news_feed=recent&amp;posts_limit=10&amp;text_color=333333&amp;title=Latest%20Kaspa%20News" height="500px"></iframe>
                    </div>
                    <div className="column">
                    <iframe width="100%" scrolling="yes" allowtransparency="true" frameborder="0" src="https://cryptopanic.com/widgets/news/?bg_color=FFFFFF&amp;currencies=BTC%2CETH%2CALPH%2CQUBIC%2CZEPH&amp;font_family=sans&amp;header_bg_color=30343B&amp;header_text_color=FFFFFF&amp;link_color=0091C2&amp;news_feed=recent&amp;posts_limit=10&amp;text_color=333333&amp;title=My%20Other%20Crypto%20News" height="500px"></iframe>
                    </div>
                    <div className="column">
                    <iframe width="100%" scrolling="yes" allowtransparency="true" frameborder="0" src="https://cryptopanic.com/widgets/news/?bg_color=FFFFFF&amp;font_family=sans&amp;header_bg_color=30343B&amp;header_text_color=FFFFFF&amp;link_color=0091C2&amp;news_feed=trending&amp;posts_limit=10&amp;text_color=333333&amp;title=Trending%20News" height="500px"></iframe>
                    </div>
                </div>
            </div>
        </article>
        </>
    );
};

export default CryptoNewsPanel;
